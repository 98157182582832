<template>
  <div>
    <vm-table
      ref="vmTable"
      v-loading="loading"
      :filter.sync="search"
      url="userLoginLog"
      @clear="clearDateRange">
      <template slot="adSearch">
        <vm-search :label="$t('maintWorkOrder.filter')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
        <vm-search :label="$t('userLoginLog.loginType')">
          <el-select
            v-model.trim="search.loginType"
            :placeholder="$t('common.pleaseSelect')"
            style="width: 100%"
            clearable>
            <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$t('userLoginLog.loginTime')">
          <el-date-picker
            v-model="dateRange"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            :unlink-panels="true"
            :range-separator="$t('common.to')"
            :start-placeholder="$t('common.startDate')"
            :end-placeholder="$t('common.endDate')"
            :default-time="['00:00:00', '23:59:59']"
            @change="handleDateChange">
          </el-date-picker>
        </vm-search>
      </template>
      <table-column prop="userName" :label="$t('userLoginLog.userName')"></table-column>
      <table-column prop="name" :label="$t('userLoginLog.name')"></table-column>
      <table-column prop="loginType" :label="$t('userLoginLog.loginType')" align="center">
        <template #default="scope">
          <span v-if="scope.row.loginType === 'web'">
            {{ $t("userLoginLog.web") }}
          </span>
          <span v-if="scope.row.loginType === 'app'">
            {{ $t("userLoginLog.app") }}
          </span>
          <span v-if="scope.row.loginType === 'wx'">
            {{ $t("userLoginLog.wx") }}
          </span>
        </template>
      </table-column>
      <table-column prop="loginIp" :label="$t('userLoginLog.loginIp')" align="center"></table-column>
      <table-column
        prop="loginTime"
        :label="$t('userLoginLog.loginTime')"
        :width="140"
        align="center"></table-column>
    </vm-table>
  </div>
</template>
<script>
  const moduleName = "userLoginLog";
  export default {
    userName: "",
    data() {
      return {
        importLoading: false,
        loading: true,
        dateRange: [],
        search: {
          filter: "",
          startTime: "",
          endTime: "",
          loginType: "",
        },
        typeOptions: [
          { value: "web", label: this.$t("newMsgSend.msgPush") },
          { value: "wx", label: this.$t("newMsgSend.sms") },
          { value: "app", label: this.$t("newMsgSend.email") },
        ],
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageNum) {
        this.loading = false;
        this.$refs.vmTable.getList(pageNum);
      },
      handleDateChange(dateRange) {
        if (dateRange != null) {
          this.search.startTime = dateRange[0];
          this.search.endTime = dateRange[1];
        } else {
          this.search.startTime = "";
          this.search.endTime = "";
        }
      },
      clearDateRange() {
        this.dateRange = [];
      },
    },
  };
</script>
